import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const CustomPageTemplate = ({
  title,
  calltoaction = {},
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <section className="custom-page">
      <div className="container container--content">
        <h2 className="custom-page__title">{title}</h2>
        <PageContent className="custom-page__content" content={content} />
      </div>
      {calltoaction ? (
        <div className="easy-btn__center">
          <a
            href={calltoaction.link}
            target="_blank"
            rel="noopener noreferrer"
            className="easy-btn"
          >
            {calltoaction.text}
          </a>
        </div>
      ) : null}
    </section>
  );
};

const CustomPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout isSecond>
      <CustomPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        calltoaction={post.frontmatter.calltoaction}
        content={post.html}
      />
    </Layout>
  );
};

CustomPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CustomPage;

export const customPageQuery = graphql`
  query CustomPage($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "custom-page" } }
    ) {
      html
      frontmatter {
        title
        calltoaction {
          text
          link
        }
      }
    }
  }
`;
